import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './index.css';

// Import your components
import Design from './components/Design.js';
import Home from './components/Home.js';
// import Consult from './components/Consult.js'; //unused
import Services from './components/Services.js';
import Contact from './components/Contact.js';
import Quote from './components/Quote.js';
import Header from './components/Header.js'; // Import Header
import Footer from './components/Footer.js';
import Support from './components/Support.js'

function App() {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const location = useLocation();

  // Page transition animations
  const pageVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },


    
  };

  return (
    <div className="App">
      <Header toggleNav={toggleNav} showNav={showNav} /> {/* Pass props to Header component */}

      <nav className={`nav-drawer ${showNav ? 'show' : ''}`}>
        <ul>
          <li>
            <Link to="/" onClick={toggleNav}>Home</Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleNav}>Services</Link>
          </li>

          <li>
            <Link to="/quote" onClick={toggleNav}>Quote</Link>
          </li>

          {/* Horizontal Line for Section Separation */}
          <hr className="section-divider" />


          <li>
            <Link to="/contact" onClick={toggleNav}>Contact</Link>
          </li>
          <li>
            <Link to="/support" onClick={toggleNav}>Support</Link>
          </li>
        </ul>
      </nav>

      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageVariants}
          transition={{ duration: 0.5 }}
        >
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/quote" element={<Quote />} />


            <Route path="/contact" element={<Contact />} />
            <Route path="/support" element={<Support />} />
          </Routes>
        </motion.div>
      </AnimatePresence>

      <Footer />
    </div>
  );
}



export default App;
