import React from 'react';
import "../styles/Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faFileContract, faPhone } from '@fortawesome/free-solid-svg-icons';


const PRIVACY_POLICY_URL = "https://docs.google.com/document/d/17TH07e0okS7epWBi3Ggm83NckoLgnyN4XSFrdWiRPEY/";
const TERMS_OF_SERVICE_URL = "https://docs.google.com/document/d/1BdolVi6NyCPH2gpWT5-X9GSTV8-JWqh4K41637wvHEs/";

const Footer = () => {
  const meetLink = "https://calendly.com/1compoundcad/15min"; // Replace with your Calendly

  return (
    <footer className="App-footer">
      <p>© {new Date().getFullYear()} Compound CAD</p>
      <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faLock} /> Privacy Policy
      </a>
      <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFileContract} /> Terms of Service
      </a>
      <a href={meetLink} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faPhone} /> Book a Meeting
      </a>
    </footer>
  );
};

export default Footer;
