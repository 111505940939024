// Contact.js
import React from 'react';

const Contact = () => {
  return (
    <section className="Contact" id="contact">
      <h2>Contact</h2>
      <p>Get in touch with us today.</p>
    </section>
  );
};

export default Contact;