import React from 'react';

function Home() {
  return (
    <section className="Home"> {/* Add a class for styling */}
      <h1>Contract Design, CAD + ERP Consulting</h1>
      <p>Contract designer and integrator of open-source CAD and ERP software for your operations.</p>
      <p>Customizing open source software. Priced in USD.</p>

      <a href="https://compoundcad.com" class="back-to-main">Back to Main Site</a>
    </section>
  );
}

export default Home;