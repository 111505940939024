import React from 'react';
import GoogleForm from './QuoteGoogleForm.js'; // Assuming the component is in a separate file
import '../styles/Quote.css'; // Assuming the CSS file is in the same directory as the component





function quote() {
  return (
    <div className="quote">
        <GoogleForm />
    </div>
  );
}





export default quote;
