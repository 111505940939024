import React from 'react';
import '../styles/Header.css'; // Import your CSS file
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for hamburger

function Header({ toggleNav, showNav }) {
  return (
    <header className="App-header">
      <h1> <a href="https://www.hq.compoundcad.com">Compound CAD Headquarters</a></h1>
      <button className="nav-toggle" onClick={toggleNav}>
        {showNav ? <FaTimes /> : <FaBars />} {/* Toggle between hamburger and close icon */}
      </button>
    </header>
  );
}

export default Header;
