// Services.js
import React from 'react';
import '../styles/Services.css'; // Import the styles for the services page

const Services = () => {
  return (
    <section className="Services" id="services">
      <h2>Our Services</h2>
      <p>We offer a range of design and consulting services to meet your needs.</p>

      <div className="service-card">
        <img src="" alt="CAD Design" />
        <h3>Contract CAD</h3>
        <p>Expert 3D modeling and 2D drafting design services.</p>
        <a href="/quote">Get a Quote (RFQ)</a>
      </div>

      <div className="service-card">
        <img src="path/to/your/image.jpg" alt="Consulting" />
        <h3>FreeCAD & ERPNext Consulting</h3>
        <p>Professional advice to optimize your design process and operations.</p>
        <a href="#">Book a Complimentary Consultation</a>
      </div>

      <div className="service-card">
        <img src="path/to/your/image.jpg" alt="Development" />
        <h3>FreeCAD & ERPNext Development</h3>
        <p>Turning your ideas from functional prototypes to production tools.</p>
        <a href="#">Get a Quote (RFQ)</a>
      </div>
    </section>
  );
};

export default Services;
