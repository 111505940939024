import React from 'react';
import '../styles/QuoteGoogleForm.css'; // Import your CSS file

function GoogleForm() {
  const formUrl = 'https://docs.google.com/forms/d/1zEQ7isOks4yZIFhXupiJvfyr6ro9Vlu8fGKbSwfJlx8/viewform?embedded=true';

  return (
    <div className="form-container">
      <h2>Request a Quote</h2>
      <iframe
        title="Request a Quote"
        src={formUrl}
        frameBorder="0"
        width="100%"
        height="700"
        allowFullScreen={true}
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        msallowfullscreen="true"
      ></iframe>
    </div>
  );
}

export default GoogleForm;
