import React, { useEffect } from 'react';

const Support = () => {
  return (
    <div>
      <section className="Support" id="support">
        <h2>Support</h2>
        <p>We're here to help.</p>
      </section>
    </div>
  );
};

export default Support;
